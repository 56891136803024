@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-icons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-notifications/styles/material.css";
@import "../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import '../node_modules/@syncfusion/ej2-angular-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-inplace-editor/styles/material.css';
/* Defined Colors */
html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

hr {
  width: 99%;
}

li {
  color: black;
}

/* Deleted Content in Notes - Marked as Deleted */
del {
  color: gray;
}
del p {
  color: gray;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

h1 {
  font-size: 22px !important;
  color: #002b5c;
  font-variant: small-caps !important;
  font-weight: bold !important;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-block-end: 0px !important;
  text-align: left;
}

h2 {
  font-size: 18px !important;
  color: #002b5c;
  font-variant: small-caps !important;
  font-weight: bold !important;
  padding-top: 15px;
  padding-bottom: 0px;
  margin-block-end: 0px !important;
  text-align: left;
}

/*   
  Small text to be in header such as (section)
*/
.greySubH2 {
  font-size: 12px !important;
  color: gray;
  font-weight: 100;
}

h3 {
  font-size: 14px;
  color: #002b5c;
  font-variant: small-caps;
  font-weight: bold !important;
  padding-top: 15px;
  padding-bottom: 0px;
  margin-block-end: 0px !important;
  text-align: left;
}

h4 {
  font-size: 12px;
  color: #002b5c;
  font-variant: small-caps;
  font-weight: bold !important;
  padding-top: 10px;
  padding-bottom: 0px;
  margin-block-end: 0px !important;
  text-align: left;
}

p {
  font-size: 14px;
  color: black;
  text-align: left;
}

hr.light {
  margin-top: 15px;
  border-top: 1px dashed whitesmoke;
  width: 90%;
}

.content {
  border-radius: 10px;
  margin: 16px;
  margin-left: 32px;
  justify-content: left;
  align-items: left;
  color: lightgray;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 5px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 800px) {
  .content {
    margin-left: 16px;
  }
}
/*
  Form Display with 2 columns -- eg: Settings pages
  1 --> Main Form
  2 --> Additional Information
*/
.twoCol {
  padding-top: 10px;
  padding-bottom: 5px;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.twoCol .colOne {
  text-align: left;
}
.twoCol .colTwoInfo {
  background-color: #F5F5F5;
  padding: 20px;
}
.twoCol .colTwoInfo h2 {
  font-size: 14px;
  color: black;
}
.twoCol .colTwoInfo h3 {
  font-size: 13px;
  color: black;
  padding-top: 5px;
  padding-bottom: 2px;
}
.twoCol .colTwoInfo h4 {
  font-size: 12px;
  color: black;
  padding-top: 5px;
  padding-bottom: 2px;
}
.twoCol .colTwoInfo p {
  font-size: 12px;
  color: black;
  margin-top: 2px;
}

/*Rules for sizing the icon*/
/* Proper way to use the following is...
    <mat-icon class="material-icons md-18">
        content_copy
    </mat-icon>
*/
.material-icons.md-8 {
  font-size: 8px !important;
}

.material-icons.md-12 {
  font-size: 12px !important;
}

.material-icons.md-14 {
  font-size: 14px !important;
}

.material-icons.md-16 {
  font-size: 16px !important;
}

.material-icons.md-18 {
  font-size: 18px !important;
}

.material-icons.md-20 {
  font-size: 20px !important;
}

.material-icons.md-22 {
  font-size: 22px !important;
}

.material-icons.md-24 {
  font-size: 24px !important;
}

.material-icons.md-28 {
  font-size: 28px !important;
}

.material-icons.md-32 {
  font-size: 32px !important;
}

.material-icons.md-36 {
  font-size: 36px !important;
}

.material-icons.md-48 {
  font-size: 48px !important;
}

.iconBlue {
  color: #055CC2;
}

.iconRed {
  color: red;
}

/**
    sets the position to relative and defines a fixed width and height for the icon. We then use the :before and :after pseudo-elements to create two invisible overlays that cover the clickable area around the icon.

The :before pseudo-element has a z-index of 1 to ensure it appears behind the icon, while the :after pseudo-element has a higher z-index of 2 and an initial opacity of 0 to make it invisible.

Finally, we use the :hover pseudo-class to change the opacity of the :after pseudo-element to 1 when the mouse is over the icon, effectively expanding the clickable area.

With this approach, you can increase the clickable area around an icon without affecting the visible spacing around the icon.
*/
.biggerClickArea {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.biggerClickArea:before,
.biggerClickArea:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
}

.biggerClickArea:before {
  z-index: 1;
}

.biggerClickArea:after {
  z-index: 2;
  opacity: 0;
}

.biggerClickArea:hover:after {
  opacity: 1;
}

/* end of bigger click */
.buttonIcon {
  width: 20px;
  height: 20px;
}

.buttonIconLeftOfText {
  width: 20px;
  height: 20px;
  padding-right: 5px;
}

.iconPaddingLeft {
  padding-left: 5px;
}

/* for placing icon next to textbox form element  */
.iconPushUpFromBottomAndPadLeft {
  padding-left: 5px;
  padding-bottom: 25px;
}

.iconPaddingSides {
  padding-left: 5px;
  padding-right: 5px;
}

.iconInfo {
  color: #055CC2;
  font-size: 22px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: hand;
}

.error {
  color: red;
  font-size: 12px;
}

/* Displays Investigator Message for Forensic Witness in Notes */
pre {
  text-wrap: wrap;
}

.avatar {
  width: 36px;
  height: 36px;
  background-image: linear-gradient(to right bottom, rgb(98, 127, 255), rgb(75, 224, 152));
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 12px;
}

.e-avatar.blue {
  background-image: linear-gradient(to right bottom, rgb(98, 127, 255), rgb(75, 224, 152));
}

.e-avatar-large {
  font-size: 2em !important;
}

/* Material Form & Input Overrides 

  NOTE: the indigo-pink theme overrides these and makes them too big */
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0px !important;
}

.mat-form-field-infix {
  padding: 0px !important;
  min-height: 38px !important;
}

.mat-radio-label-content {
  padding-right: 20px !important;
}

/* Remove rounded button effect */
.mat-icon-button {
  border-radius: 0 !important;
}

.mat-icon {
  font-size: 30px !important;
  vertical-align: middle !important;
}

.mat-button {
  text-align: left !important;
  line-height: 40px !important;
}

.mat-icon-menu-button {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
}
.mat-icon-menu-button mat-icon {
  margin-right: 8px;
  width: 30px;
  height: 30px;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  margin-top: 0.2em !important;
}

.png-mat-icon {
  margin-right: 8px;
  background-repeat: no-repeat no-repeat;
  width: 30px;
  height: 30px;
}

.icon-external {
  transform: scale(0.7);
  padding-left: 7px;
}

input.mat-input-element {
  min-height: 38px !important;
  color: black;
}

.matLabelWithNoOutline {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1em !important;
  font-weight: bold !important;
  padding-top: 1em;
}

.matLabelWithNoOutlineMinimalTopPadding {
  margin-top: 5px !important;
  padding-top: 0px !important;
  color: rgba(0, 0, 0, 0.6);
  font-size: 1em !important;
  font-weight: bold !important;
}

.setupRequiredInfoBox {
  background-color: lightgray;
  border-radius: 10px;
  padding: 1em;
}
.setupRequiredInfoBox h3 {
  padding: 0px;
}

.hand {
  cursor: pointer;
}

.help {
  cursor: help;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.disabledButActiveClick {
  opacity: 0.3;
}

.whiteText {
  color: white;
}

.center {
  text-align: center;
}

.superCenter {
  display: grid;
  place-items: center;
}

.justify-content-center {
  justify-content: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.centerDiv {
  margin: auto;
  padding: 10px;
  text-align: center;
}

.text-center {
  text-align: center;
}

.down-arrow-icon {
  display: inline-block;
  height: 20px;
}

.down-arrow-icon .arrow-icon {
  background: url(/assets/images/arrow-icon.png) center no-repeat;
  background-size: 18px;
  width: 100%;
  display: inline-block;
  height: 18px;
  vertical-align: middle;
  position: relative;
  transform: rotate(180deg);
}

.down-arrow-icon.up {
  position: absolute;
  top: 12px;
  width: 55px;
  display: block;
}

.down-arrow-icon.up,
.down-arrow-icon {
  display: none;
}

.down-arrow-icon .arrow-icon {
  transform: rotate(360deg);
}

/* make sure toast shows in front of dialogs */
toast {
  z-index: 99999;
}

.toast-success {
  color: white;
}
.toast-success p {
  color: white;
}
.toast-success li {
  color: white;
}

.toast-info {
  color: white;
}
.toast-info p {
  color: white;
}
.toast-info li {
  color: white;
}

.toast-error {
  color: white;
}
.toast-error p {
  color: white;
}
.toast-error li {
  color: white;
}

#toast-container > div {
  opacity: 1;
  z-index: 99999;
}

.smallgrey {
  font-size: 10px;
  color: gray;
}

.requiredFormField {
  color: red;
  font-size: 10px;
}

.fullheight {
  height: 100vh;
}

.fullWidth {
  flex: 1 1 auto;
}

.row {
  display: flex;
}

.rowColumn {
  display: flex;
  flex-direction: column;
}

.rowFull {
  display: flex;
  flex: 100%;
}

.rowFullWrap {
  display: flex;
  flex: 100%;
  flex-wrap: wrap;
  align-items: center;
}

.rowFullWrapTopSpaceBetween {
  display: flex;
  flex: 100%;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-between;
}

.rowFullWrapBottomSpaceBetween {
  display: flex;
  flex: 100%;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-between;
  align-items: flex-end;
}

.rowSpace {
  padding: 1em;
}

.iconsNextToEachOther {
  display: flex;
  flex: 100%;
  flex-wrap: wrap;
  gap: 1px;
  justify-content: space-between;
  align-items: flex-end;
}

/*
  Icon column - this sets the height so it matchs the controls so
  that the center is true to control next to it.
*/
.adjustHeightForIcon {
  height: 34px;
}

/*
  Aligns Controls to the bottom of the flex grid and removes the padding
  on the bottom of Mat Controls to lineup with other controls like
  dropdown.
*/
.alignBottom {
  align-items: flex-end;
}
.alignBottom .mat-form-field-wrapper {
  padding-bottom: 0;
}

.paddingSpace1em {
  padding-top: 1em;
}

.rowSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.column {
  flex: 50%;
}

.column10 {
  flex: 10%;
}

.column20 {
  flex: 20%;
}

.column25 {
  flex: 25%;
}

.column40 {
  flex: 40%;
}

.column45 {
  flex: 45%;
}

.column50 {
  flex: 50%;
}

.column75 {
  flex: 75%;
}

.column90 {
  flex: 75%;
}

.headerRow {
  display: flex;
  flex: 100%;
  flex-wrap: wrap;
  gap: 1em;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.headerRowButtonRight {
  text-align: right;
  display: inline-block;
}

/* Mobile ONLY */
@media (max-width: 430px) {
  .headerRow {
    gap: 0em;
  }
  .headerRow h1 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .headerRowButtonRight button {
    margin-top: 2px !important;
  }
}
.columnDisplay {
  width: 300px;
  background-color: pink;
}

.flex-container {
  display: flex;
}

.display-inline {
  display: inline-block;
}

/* Google Maps Auto-Complete Dialog
    to ensure it shows over the other popup dialogs.
*/
.pac-container {
  background-color: #FFF;
  z-index: 2000 !important;
  position: fixed;
  display: inline-block;
}

/*  
  Images in Notes - This corrects images that do not contain a Max-Width alread in the note.
  so the image goes off the screen or creates horizontal scroll.
  This SHOULD BE in the editNote scss
  BUT it does not work there and works here for some reason. ??
*/
img[id^=thumb] {
  max-width: 100%;
}

.imgFit {
  max-width: 100%;
}

/* Imaged Embedded in Notes - For display in APP only  */
.embeddedImages {
  max-width: 200px;
  max-height: 150px;
}

.countryFlagInDropDown {
  max-width: 30px;
  max-height: 100%;
}

.countryDropDownText {
  font-size: 1em;
  text-align: left;
}

.loadingDiv {
  background-color: white;
  position: absolute;
  display: grid;
  place-items: center;
  align-content: center;
  top: 0px;
  bottom: 0px;
  right: 0;
  left: 0px;
  z-index: 99999;
  padding: 50px;
}
.loadingDiv .loadingLogo {
  max-width: 100%;
}
.loadingDiv .loadingAppGif {
  width: 100px;
  height: 100px;
}

.networkOfflineDiv {
  background-color: white;
  position: absolute;
  display: grid;
  place-items: center;
  align-content: center;
  top: 0px;
  bottom: 0px;
  right: 0;
  left: 0px;
  z-index: 99999;
  padding: 50px;
}

.hidden {
  display: none;
}

@media (max-width: 600px) {
  .loadingDiv .loadingAppGif {
    width: 50px;
    height: auto;
  }
}
.note-loader {
  top: 65px;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  display: grid;
  z-index: 9999;
  height: calc(100vh - 180px);
  margin-top: 110px;
  margin: auto;
  margin: 30px;
  /* margin-top: 128px; */
}

.note-loader img {
  height: 30px;
  margin: auto;
}

.light {
  max-width: 735px;
  margin: auto;
  top: 0;
  width: 100%;
  height: auto;
  position: unset;
}

.note-loader.light {
  max-width: 735px;
  margin: auto;
  top: 0;
  position: unset;
}

.inner-note-loader p {
  color: #a4a4a4;
  font-weight: 500;
  font-size: 18px;
  position: relative;
}

.inner-note-loader {
  left: 0;
  right: 0;
  display: grid;
  place-items: center;
  z-index: 9999;
  height: 50px;
}

.inner-note-loader > img {
  width: 120px !important;
}

.notebookCreatedHeader {
  position: relative;
}

@media (max-width: 600px) {
  .inner-note-loader p {
    font-size: 15px;
  }
  .inner-note-loader {
    margin-bottom: 10px;
  }
}
/*
  Show / Hide Sections based on if Mobile.
*/
.hideFlexRowOnMobile {
  display: flex;
}

.showOnMobile {
  display: none;
}

/* Mobile ONLY */
@media (max-width: 430px) {
  /* Hide sections on mobile devices */
  .hideOnMobile {
    display: none;
  }
  /* Show section ONLY on mobile -- can be used to show message when content hidden */
  .showOnMobile {
    display: inline-block;
  }
  .hideFlexRowOnMobile {
    display: none;
  }
}
/*
 Case Management UI Components for Displaying Details
*/
.caseDetailsUiDisplay .title {
  font-size: 14px;
  font-weight: bold;
  padding-top: 5px;
}
.caseDetailsUiDisplay .titleInline {
  display: inline-block;
  width: 155px;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  padding-right: 10px;
}
.caseDetailsUiDisplay .details {
  font-size: 14px;
  color: black;
}

/** 
* Dialog Styles
*/
.e-dialog .e-dlg-header {
  color: #002B5C;
  font-size: 20px;
  font-weight: bold;
}

.e-dialog {
  font-size: 14px;
  font-weight: normal;
  align-content: center;
  vertical-align: middle;
  margin-bottom: 10px;
  /*
  ShortCut info at bottom of Dialog
  */
  /* Mobile ONLY */
}
.e-dialog .heading {
  font-weight: bold;
  margin-left: 0px;
  margin-right: 10px;
  font-size: 1.2em;
}
.e-dialog .subheading {
  font-weight: bold;
  margin-left: 0px;
  margin-right: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 1.1em;
}
.e-dialog .removeTopMargin {
  margin-top: 0px !important;
}
.e-dialog p {
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  margin-bottom: 10px;
  margin-top: 10px;
}
.e-dialog .specialNote {
  font-size: 12px;
  font-weight: normal;
  color: grey;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.e-dialog ul li {
  font-size: 14px;
  vertical-align: middle;
}
.e-dialog label {
  font-size: 14px;
  vertical-align: middle;
  margin-left: 5px;
  letter-spacing: 0.5px;
  cursor: pointer;
}
.e-dialog .questionAnswer {
  display: inline-block;
  width: 75px;
  font-weight: bold !important;
}
.e-dialog .shortCuts {
  color: grey;
  font-size: small;
  text-align: center;
  padding-top: 10px;
}
@media (max-width: 430px) {
  .e-dialog {
    /* Hide sections on mobile devices */
  }
  .e-dialog .hideOnMobile {
    display: none;
  }
}
@media (min-width: 768px) {
  .e-dialog button {
    padding: 5px 15px;
  }
}
.e-dialog .section {
  font-size: 14px;
  font-weight: normal;
  align-content: center;
  vertical-align: middle;
  margin-bottom: 10px;
}
.e-dialog .section .title {
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
}
.e-dialog .section .titleInline {
  display: inline-block;
  width: 100px;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  padding-right: 10px;
}
.e-dialog .section .details {
  font-size: 14px;
  color: black;
}
.e-dialog .section .titleGeekStats {
  font-size: 10px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
}
.e-dialog .sectionGeekStats {
  color: grey;
  font-size: 10px;
  font-weight: normal;
  align-content: center;
  vertical-align: middle;
  margin-bottom: 10px;
}
.e-dialog .sectionGeekStats .titleGeekStats {
  font-size: 10px;
  font-weight: bold;
  margin-right: 10px;
}
.e-dialog .validationError {
  color: red;
  font-size: 12px;
}
.e-dialog .disclaimer {
  font-size: 12px;
  color: darkgray;
}

/* Animated GIF */
.button-spinner {
  width: 15px;
  height: 15px;
  margin-right: 3px;
}

.sectionInfo {
  font-size: 14px;
  color: grey;
  margin-block-end: 0px !important;
}

input {
  font-size: 14px;
  vertical-align: middle;
}

.inputEffect {
  border: 0;
  padding-top: 5px;
  padding-bottom: 10px;
  border: 1px solid transparent;
  border-bottom-color: #ccc;
  transition: 0.4s;
  text-overflow: ellipsis;
}

.content-wrapper {
  width: 90%;
  margin: 0 auto;
  min-width: 185px;
  padding-bottom: 10px;
}

.buttonSectionOneCol {
  padding-top: 10px;
  padding-bottom: 5px;
  display: grid;
  place-items: center;
  align-items: center;
}

.buttonSectionOneColRight {
  text-align: right;
  width: 100%;
}

.buttonSectionTwoCol {
  padding-top: 10px;
  padding-bottom: 5px;
  display: grid;
  place-items: center;
  align-items: center;
  grid-template-columns: repeat(2, auto);
}

/*
  Top Display for Forms to show Header along with right side content (eg: Change password)
*/
.twoColHeader {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}
.twoColHeader .colOne {
  vertical-align: top;
}
.twoColHeader .colTwo {
  padding-top: 15px !important;
  vertical-align: top;
  text-align: right;
}

.buttonRight {
  text-align: right;
  display: inline-block;
  width: 100%;
}

.noTopBottomMargins {
  margin: 0px !important;
}

button.success {
  background-color: #13aa52;
  border: 1px solid #13aa52;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 5px 15px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

button.success:focus {
  border: 2px solid black;
}

button.success:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  touch-action: none;
  transition: none;
  transform: none;
}

button.cancel {
  background-color: grey;
  border: 1px solid grey;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 5px 15px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

button.cancel:focus {
  border: 2px solid black;
}

button.delete {
  background-color: red;
  border: 1px solid red;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 5px 15px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

button.delete:focus {
  border: 2px solid black;
}

button.SiteColor {
  background-color: #055CC2;
  border: 1px solid #055CC2;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 5px 15px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

button.SiteColor:focus {
  border: 2px solid black;
}

button:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  transform: translateY(-2px);
}

/* (click)='' div tags to look like hyperlinks */
.divHyperlink {
  color: blue;
  font-size: 1em;
  cursor: pointer;
}

.divHyperlinkSmall {
  color: blue;
  font-size: 0.8em;
  cursor: pointer;
}

.greyMsg {
  color: darkgray;
}

/*
  Forms
*/
.form-fullWidth {
  width: 100%;
}

.hyphen {
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  display: grid;
  place-items: center;
  height: 43px;
  font-size: 20px;
}

.highlightedFormSection {
  background-color: lightblue;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  width: 250px;
}

.makeSelection {
  font-size: 12px;
  color: grey;
}

/**
  Form Widths
  100% of Max-Width set and automatically be smaller to fit screen
*/
.form-width-xsmall {
  width: 100%;
  max-width: 100px;
}

.form-width-small {
  width: 100%;
  max-width: 200px;
}

.form-width-smallMedium {
  width: 100%;
  max-width: 250px;
}

.form-width-medium {
  width: 100%;
  max-width: 300px;
}

.form-width-large {
  width: 100%;
  max-width: 400px;
}

.form-width-xlarge {
  width: 100%;
  max-width: 500px;
}

.required {
  color: red;
  font-size: 11px;
  font-weight: 100;
}

.errorInTextbox {
  font-size: 10px;
  color: red;
  padding-left: 5px;
  padding-bottom: 10px;
}

/* used for auto-suggest text within textbox like url or PO Tag */
.autoSuggest {
  font-size: 10px;
  color: blue;
  padding-left: 5px;
  padding-bottom: 10px;
}

/* Loading GIFs for page loads
  Main loadingDiv takes up full page.
  smaller .loadingGifSmall for small sections
*/
.loadingDiv {
  background-color: white;
  position: absolute;
  display: grid;
  place-items: center;
  align-content: center;
  top: 0px;
  bottom: 0px;
  right: 0;
  left: 0px;
  z-index: 99999;
  padding: 50px;
}
.loadingDiv .loadingLogo {
  max-width: 100%;
}
.loadingDiv .loadingAppGif {
  width: 100px;
  height: 100px;
}

.loadingGifSmall {
  width: 50px;
}

/* Paging - Removes the padding due to UL in Paging Control */
ul.ngx-pagination.ng-star-inserted {
  padding-inline-start: 0px !important;
}

/* Canvas used to highlight the Signature Pad */
canvas {
  border: 1px solid #898989;
  display: flex;
  margin: auto;
}

.tox .tox-notification--error {
  display: none !important;
}

.tinyBorder {
  border: 1px black solid;
  padding: 5px;
  border-radius: 5px;
}

/*
  EJS Controls
*/
ejs-radiobutton {
  padding-top: 10px;
}

/* 
  Info - Tips - Note
  https://www.madcapsoftware.com/blog/css-tip-creating-styled-boxes-notes-warnings-examples-tips/

*/
p.note {
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  background-color: #f0f7fb;
  background-image: url(/assets/images/note-icon.png);
  background-position: 9px 6px;
  background-repeat: no-repeat;
  border: solid 1px #3498db;
  border-radius: 6px;
  line-height: 18px;
  overflow: hidden;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 60px;
  padding-right: 15px;
}

p.noteAlt {
  background-color: #f0f7fb;
  background-image: url(/assets/images/note-icon.png);
  background-position: 9px 6px;
  background-repeat: no-repeat;
  border-left: solid 4px #3498db;
  line-height: 18px;
  overflow: hidden;
  padding: 15px 60px;
}

div.note {
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  background-color: #f0f7fb;
  background-image: url(/assets/images/note-icon.png);
  background-position: 9px 6px;
  background-repeat: no-repeat;
  border: solid 1px #3498db;
  border-radius: 6px;
  line-height: 18px;
  overflow: hidden;
  padding: 15px 60px;
}

p.tip {
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  background-color: #FFFBEA;
  background-image: url(/assets/images/bulb-32.png);
  background-position: 9px 6px;
  background-repeat: no-repeat;
  border-left: solid 4px #F2C412;
  border-radius: 6px;
  line-height: 18px;
  overflow: hidden;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 60px;
  padding-right: 15px;
}

div.tipAlt {
  background-color: #FFFFFF;
  background-image: url(/assets/images/bulb-32.png);
  background-position: 9px 6px;
  background-repeat: no-repeat;
  border-left: solid 4px #F2C412;
  line-height: 18px;
  overflow: hidden;
  padding: 15px 60px;
}

p.caution {
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  background-color: #FFFFFF;
  background-image: url(/assets/images/caution-32.png);
  background-position: 9px 6px;
  background-repeat: no-repeat;
  border-left: solid 4px #F2C412;
  border-radius: 6px;
  line-height: 18px;
  overflow: hidden;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 60px;
  padding-right: 15px;
}

div.caution {
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  background-color: #FFFFFF;
  background-image: url(/assets/images/caution-32.png);
  background-position: 9px 6px;
  background-repeat: no-repeat;
  border: solid 1px #F2C412;
  border-radius: 6px;
  line-height: 18px;
  overflow: hidden;
  padding: 15px 60px;
}
div.caution .header {
  font-weight: bold;
  font-variant: small-caps;
  margin-top: 0px;
}

/**
  Tab Control
*/
.overviewIcon:before {
  font-family: "Material Icons";
  content: "info_outline";
}

.noteIcon:before {
  font-family: "Material Icons";
  content: "text_snippet";
}

.entityIcon:before {
  font-family: "Material Icons";
  content: "fingerprint";
}

.e-tab {
  border: 1px solid #F8F8F8;
  background-color: #F8F8F8;
  padding: 10px !important;
}

.e-tab .e-tab-header {
  background: #F8F8F8 !important;
}

.bootstrap-tab.e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-tab-text,
.bootstrap-tab.e-tab .e-tab-header .e-toolbar-item.e-active.e-iright .e-tab-text,
.bootstrap-tab.e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-tab-icon,
.bootstrap-tab.e-tab .e-tab-header .e-toolbar-item.e-active.e-iright .e-tab-icon,
.bootstrap-tab.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.bootstrap-tab.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  color: #002b5c;
}

.bootstrap-tab.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
  color: #002b5c;
}

.e-tab .e-tab-header .e-indicator {
  background: #002b5c;
}

/* 
Note Styles
Styles used to style notes within Editor. Required here for display and needed in the PDF Gen.
NOTE: This style MUST match the style as defined in the advanced.component.html file under 'context_style'
*/
.blockHeader {
  display: block;
  background-color: black;
  padding: 5px;
  color: white;
  font-weight: bold;
  text-align: center;
  font-variant: small-caps;
  font-size: 18px;
}

blockquote {
  margin: 0;
}

blockquote p {
  padding: 10px;
  margin-left: 10px !important;
  margin-right: 10px !important;
  background: whitesmoke;
  border-radius: 5px;
}

blockquote p::before {
  content: "“";
}

blockquote p::after {
  content: "”";
}

code {
  background-color: lightgray;
  padding: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

/*
  InnerHtml Sanitize Bug
  If <br />, then its gets repalced by LOTS of <br> tags if above a table
  causing drastic display issues.
  See: https://forensicnotes.atlassian.net/wiki/spaces/DEV/pages/2010218515/SafeHtml+BUG
*/
p.brParagraph {
  white-space: pre-wrap;
  padding: 0px;
  margin: 0px;
}